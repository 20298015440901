// TODO: Random / ordered dropdown doesn't show the right value
// TODO: Have to type twice to start wrting an answer
// TODO: UX of dropdown doesn't look right - hard to tell which question you're looking / what's the new one
// TODO: No way to delete questions

import React, { useState, useEffect } from 'react';
import { useSpring } from 'react-spring';

import { Button, Flex, MdIcon, Text, Spinner } from '@workshop/ui';

import { AnimatedFlex } from 'components/Common';

const noop = () => null;

interface AnswerChoice {
  content: string;
  id: number;
  isCorrect: boolean;
}

export interface MultipleChoiceQuestion {
  answers: AnswerChoice[];
  explanation?: React.ReactNode;
  onSubmit?: (answers: number[]) => Promise<void> | void;
  question: string;
  title?: React.ReactNode;
}

type MCQProps = MultipleChoiceQuestion;

interface MCQFooterProps {
  allSelected: boolean;
  noneSelected: boolean;
  onSelectAll: () => void;
  onSubmit: () => void;
  question: string;
  submittingAnswers: boolean;
  multipleCorrectAnswers: boolean;
}

const MCQFooterSection: React.FC<MCQFooterProps> = ({
  allSelected,
  noneSelected,
  onSelectAll,
  onSubmit,
  question,
  submittingAnswers,
  multipleCorrectAnswers,
}) => {
  const [helpActive, setHelpActive] = useState(false);

  const helpSectionStyle = useSpring({
    opacity: helpActive ? 1 : 0,
  });

  useEffect(() => {
    setHelpActive(false);
  }, [question]);

  return (
    <Flex flexDirection="column" alignItems="center">
      {multipleCorrectAnswers && (
        <Text
          color={allSelected ? 'text.muted' : 'common.primary'}
          cursor={allSelected ? 'initial' : 'pointer'}
          fontSize="xs"
          fontWeight="semibold"
          mt="defaultMargin"
          onClick={onSelectAll}
        >
          Select All
        </Text>
      )}
      <Button
        isDisabled={noneSelected}
        onClick={onSubmit}
        mt={4}
        mb="defaultMargin"
        minWidth="200px"
      >
        {submittingAnswers ? <Spinner size="xs" speed="0.75s" /> : 'Submit'}
      </Button>
      {helpActive ? (
        // @ts-ignore
        <AnimatedFlex
          alignItems="center"
          backgroundColor="background.tint1"
          borderRadius="md"
          flexDirection="column"
          maxW="550px"
          padding="defaultPadding"
          style={helpSectionStyle}
          textAlign="center"
        >
          <Text mb={4}>
            Feel free to do some research online if you're not sure about the
            answer:
          </Text>
          <Button mb={4} icon="OpenInNew" secondary>
            <a href="http://www.google.com/" target="_blank" rel="noreferrer">
              Open Google
            </a>
          </Button>
          <Text mb={4}>
            Learning how to find answers to your questions is just as important
            as learning the answers themselves!
          </Text>
          <Text
            cursor="pointer"
            color="common.primary"
            fontSize="xs"
            fontWeight="semibold"
            onClick={() => setHelpActive(false)}
          >
            Dismiss
          </Text>
        </AnimatedFlex>
      ) : (
        <Button secondary onClick={() => setHelpActive(true)} minWidth="200px">
          Not sure?
        </Button>
      )}
    </Flex>
  );
};

const MCQ: React.FC<MCQProps> = ({
  answers,
  explanation,
  onSubmit = noop,
  question,
  title,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(question);
  const [answersSubmitted, setAnswersSubmitted] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [submittingAnswers, setSubmittingAnswers] = useState(false);

  useEffect(() => {
    setCurrentQuestion(question);
    setAnswersSubmitted(false);
    setSelectedIds([]);
  }, [question]);

  const selectedAnswerIds = currentQuestion === question ? selectedIds : [];

  const toggleAnswerSelected =
    (answerId: number, isSelected: boolean, multipleCorrectAnswers: boolean) =>
    () =>
      setSelectedIds(
        multipleCorrectAnswers
          ? isSelected
            ? selectedAnswerIds.filter((id) => id !== answerId)
            : [...selectedAnswerIds, answerId]
          : [answerId]
      );

  const noneSelected = !selectedAnswerIds.length;
  const allSelected = selectedAnswerIds.length === answers.length;

  const selectAll = () =>
    allSelected ? null : setSelectedIds(answers.map((a) => a.id));

  const correctAnswers = answers.filter((a) => a.isCorrect);
  const multipleCorrectAnswers = correctAnswers.length > 1;

  return (
    <Flex flexDirection="column" alignItems="center" padding={2} width="100%">
      {title && <Text>{title}</Text>}
      <Text fontWeight="semibold" mb={4}>
        {question}
      </Text>
      <Flex flexDirection="column" alignItems="center" width="100%" mb={1}>
        {answers.map(({ id, isCorrect, content }) => {
          const isSelected = selectedAnswerIds.includes(id);

          // If the answers have been submitted and the an answer wasn't
          // selected and isn't correct - don't show it. This will result
          // in only the correct and the selected incorrect answers being
          // displayed after the answers have been submitted.
          const shouldHideAnswer =
            answersSubmitted && !isSelected && !isCorrect;
          if (shouldHideAnswer) return null;

          // Once answers have been submitted it shouldn't be possible to
          // submit them again
          const onClick = answersSubmitted
            ? noop
            : toggleAnswerSelected(id, isSelected, multipleCorrectAnswers);

          let background = isSelected
            ? 'background.primary'
            : 'background.tint3';
          let iconName = isSelected
            ? 'RadioButtonChecked'
            : 'RadioButtonUnchecked';
          let iconColor = isSelected ? 'text.primary' : 'text.muted';
          let textColor = isSelected ? 'text.primary' : 'text.default';

          if (answersSubmitted) {
            background = isCorrect ? 'background.success' : 'background.error';
            iconName = isCorrect ? 'CheckCircle' : 'Cancel';
            iconColor = isCorrect ? 'text.success' : 'text.error';
            textColor = isCorrect ? 'text.success' : 'text.error';
          }

          return (
            <Flex
              key={`mcq-answer-${id}`}
              alignItems="center"
              backgroundColor={background}
              _hover={{ opacity: answersSubmitted ? 1 : 0.75 }}
              borderRadius="sm"
              cursor={answersSubmitted ? 'initial' : 'pointer'}
              marginY={1}
              onClick={onClick}
              padding={2}
              width="100%"
            >
              <MdIcon name={iconName} color={iconColor} boxSize="icon" mr={3} />
              <Text color={textColor}>{content}</Text>
            </Flex>
          );
        })}
      </Flex>
      {!answersSubmitted && (
        <MCQFooterSection
          allSelected={allSelected}
          noneSelected={noneSelected}
          question={question}
          submittingAnswers={submittingAnswers}
          multipleCorrectAnswers={multipleCorrectAnswers}
          onSelectAll={selectAll}
          onSubmit={async () => {
            setSubmittingAnswers(true);
            await onSubmit(selectedAnswerIds);

            setSubmittingAnswers(false);
            setAnswersSubmitted(true);
          }}
        />
      )}
      {answersSubmitted && explanation && (
        <Flex
          alignItems="center"
          backgroundColor="background.tint1"
          borderRadius="md"
          flexDirection="column"
          maxW="550px"
          mt={4}
          padding={4}
          textAlign="center"
        >
          <Text>{explanation}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default MCQ;
